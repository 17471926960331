import { useEffect, useState } from 'react'

import { useHistory, Link, NavLink, useLocation } from 'react-router-dom'

import { useAuthentication } from 'hooks/authentication'
import { useTranslation } from 'react-i18next'

import logo_en from 'assets/napaLogo_horizontal_darkBackground_en.svg'
import logo_fr from 'assets/napaLogo_horizontal_darkBackground_fr.svg'

import './header.scss'
import './nav.scss'
import { useApolloClient, useMutation } from '@apollo/client'
import { OAUTH_GENERATE } from 'operations/Auth'

import myNapaProgramsLogo from 'assets/programs/napaLogo.svg'
import myAagProgramsLogo from 'assets/programs/altromLogo.svg'

import { usePrevious } from 'hooks/previous'
import { useCompany } from 'hooks/company'

const logos = {
    en: logo_en,
    fr: logo_fr
}

export default function Header() {

    const company = useCompany()

    const { t, i18n } = useTranslation()

    const { isAuthenticated, user, setAuthenticated } = useAuthentication()

    const client = useApolloClient()

    const [ expanded, setExpanded ] = useState()
    const location = useLocation()
    const history = useHistory()
    const [ oauthGenerate ] = useMutation(OAUTH_GENERATE)

    const previousLocation = usePrevious(location)
    useEffect(() => {
        if (previousLocation && (location.pathname !== previousLocation.pathname)) setExpanded(false)
    }, [location, previousLocation])

    // useEffect(() => {
    //     setExpanded()
    // }, [location])

    return (
        <header className='header'>

            <div />

            <div className='logoContainer'>
                <Link to='/'><img src={ logos[(i18n.language || 'en').substr(0,2)] } alt='NAPA Rewards' /></Link>
            </div>

            <button className={`hamburgerMenu ${ expanded ? 'hamburgerMenu--active' : '' }`} onClick={() => setExpanded(expanded => !expanded)} aria-label='Navigation menu'>
                    <span className='hamburgerBox'>
                    <span className='hamburgerInner'></span>
                </span>
            </button>

            {/* <nav className={`menu ${ expanded ? 'menu--expanded' : '' }`}> */}
            <nav className={`nav__container ${ expanded ? '' : 'nav__container--hidden' }`}>
                
                <div>
                    <ul>
                        <li><NavLink exact to='/'><i className='material-icons'>home</i>{ t('Home') }</NavLink></li>
                        
                    </ul>
                    <div className='navDivider'>{ t('Resources') }</div>
                    <ul>
                        <li><NavLink to='/frequently-asked-questions'><i className='material-icons'>help</i>{ t('Frequently asked questions') }</NavLink></li>
                    </ul>
                </div>
                {
                    isAuthenticated && (
                        <div style={{ padding: '0 2rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '2rem' }}>
                                <button onClick={() => { 
                                    i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en'); 
                                    setExpanded(false); 
                                }}>
                                    <i className='material-icons'>translate</i>{ i18n.language === 'fr' ? 'English' : 'Français' }
                                </button>
                                <button onClick={() => {
                                    setAuthenticated(false)
                                    setExpanded(false)
                                    // resetGlobalState()
                                    localStorage.removeItem('accessToken')
                                    localStorage.removeItem('refreshToken')
                                    client.clearStore()
                                    history.replace('/')
                                }}>
                                    <i className='material-icons'>logout</i>{ t('logout', 'Logout') }
                                </button>
                            </div>
                            {
                                !['CONSUMER'].includes(user.userType) && (
                                    <button 
                                        className='myProgramsButton'
                                        onClick={async () => { 
                                            const { data } = await oauthGenerate()
                                            const { oauthToken, oauthRedirect } = data.oauthGenerate
                                            const uri = `${ oauthRedirect }/oauth/${ i18n.language }/${ oauthToken }`
                                            window.location.replace(uri)
                                        }}
                                    >
                                        <div>
                                            <img src={ company === 'AAG' ? myAagProgramsLogo : myNapaProgramsLogo } alt='Programs Logo'/>
                                        </div>
                                        <div>
                                            { company === 'NAPA' ? t('My NAPA Programs') : t('My AAG Programs') }
                                        </div>
                                    </button>
                                )
                            }
                        </div>
                    )
                }
            </nav>

        </header>
    )

}

// function LogoutButton({ ...rest }) {

//     const { t } = useTranslation()

//     const { setAuthenticated } = useAuthentication()
//     const history = useHistory()
//     const client = useApolloClient()

//     return (
//         <button type='button' className='button__logout' { ...rest }
//             onClick={() => {
//                 setAuthenticated(false)
//                 localStorage.removeItem('accessToken')
//                 localStorage.removeItem('refreshToken')
//                 client.clearStore()
//                 history.replace('/')
//             }}
//         >{ t('Sign out') }</button>
//     )
// }