import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import AuthenticationProvider from 'hooks/authentication'
import CompanyProvider from 'hooks/company'
import TueriProvider from 'components/Tueri'
import RefreshProvider from 'hooks/useRefresh'
import client from 'Apollo'
import Routes from 'Routes'
import Loader from 'components/Loader'

import './i18n'

import './styles/default.scss'

ReactDOM.render(
  <CompanyProvider>
    <RefreshProvider>
      <ApolloProvider client={ client }>
        <AuthenticationProvider>
            <Suspense fallback={ <Loader /> }>
              <TueriProvider replacements={[
                ["https://napa-rebates.nyc3.cdn.digitaloceanspaces.com/", "https://cdn.tueri.io/68719476737/naparebates/"],
                ["https://napa-rebates.nyc3.digitaloceanspaces.com/", "https://cdn.tueri.io/68719476737/naparebates/"]
              ]}>
                <Routes />
              </TueriProvider>
            </Suspense>
        </AuthenticationProvider>
      </ApolloProvider>
    </RefreshProvider>
  </CompanyProvider>,
  document.getElementById('root')
);
