import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-locize-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { locizePlugin } from 'locize'

const backendOptions = {
    projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
    referenceLng: 'en',
}

i18n.use(locizePlugin)
i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    // saveMissing: process.env.NODE_ENV === 'development' ? true : false,
    // updateMissing: process.env.NODE_ENV === 'development' ? true : false,
    saveMissing: true,
    updateMissing: true,
    fallbackLng: 'en',
    defaultNS: 'translation',
    backend: backendOptions,
    react: {
        bindI18n: 'languageChanged editorSaved'
    }
})

export default i18n