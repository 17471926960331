import gql from 'graphql-tag'

export const MAGIC_AUTHENTICATION = gql`
    mutation MagicAuthentication($payload: MagicAuthPayload!) {
        magicAuthentication(payload: $payload) {
            id
            usernameType
            expiresIn
        }
    }
`

export const SECURITY_CODE_AUTH = gql`
    mutation SecurityCodeAuth($payload: SecurityCodePayload!) {
        securityCodeAuth(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const REFRESH_TOKEN_CONSUMER = gql`
    mutation refreshToken($payload: RefreshTokenPayload!) {
        refreshToken: refreshTokenRedis(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const REFRESH_TOKEN = gql`
    mutation RefreshToken($payload: RefreshTokenPayload!) {
        refreshToken(payload: $payload) {
            accessToken
        }
    }
`

export const OAUTH_AUTHENTICATE = gql`
    query OauthAuthenticate($payload: OauthPayload! ) {
        oauthAuthenticate(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const OAUTH_GENERATE = gql`
    mutation OauthGenerate {
        oauthGenerate {
            oauthToken
            oauthRedirect
        }
    }
`