import './layout.scss'
import { useApolloClient, useMutation } from '@apollo/client'
import { NavLink } from 'react-router-dom'
import { OAUTH_GENERATE } from 'operations/Auth'
import { useAuthentication } from 'hooks/authentication'

export default function Layout({ children }) {
	return <div className="wrapper">{children}</div>
}

export function AgentLayout({ children }) {
	return (
		<div className="wrapper--agent">
			<div className="navContainer">
				<AgentNav />
			</div>
			<div className="bodyContainer">{children}</div>
		</div>
	)
}

function AgentNav() {
	const { setAuthenticated } = useAuthentication()
	const client = useApolloClient()
	const [oauthGenerate] = useMutation(OAUTH_GENERATE)

	return (
		<nav>
			<ul>
				<li>
					<NavLink to="/">
						<i className="material-icons">speed</i>Dashboard
					</NavLink>
				</li>
				<li>
					<NavLink to="/claims">
						<i className="material-icons">all_inbox</i>Claims
					</NavLink>
				</li>
				<li>
					<NavLink to="/customers">
						<i className="material-icons">people</i>Customers
					</NavLink>
				</li>
				<li>
					<NavLink to="/exports">
						<i className="material-icons">file_download</i>Export
					</NavLink>
				</li>
				<li>
					<NavLink to="/import">
						<i className="material-icons">file_upload</i>Import
					</NavLink>
				</li>
				<li>
					<NavLink to="/reports">
						<i className="material-icons">analytics</i>Reports
					</NavLink>
				</li>
				<li>
					<button
						type="button"
						onClick={async () => {
							const { data } = await oauthGenerate()
							const { oauthToken, oauthRedirect } =
								data.oauthGenerate
							const uri = `${oauthRedirect}/oauth/en/${oauthToken}`
							setAuthenticated(false)
							localStorage.removeItem('accessToken')
							localStorage.removeItem('refreshToken')
							client.clearStore()
							window.location.replace(uri)
						}}
					>
						<i className="material-icons">exit_to_app</i>Exit
					</button>
				</li>
			</ul>
		</nav>
	)
}
