import { useEffect, Suspense, lazy } from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useHistory,
	useParams,
} from 'react-router-dom'
import { useAuthentication } from 'hooks/authentication'

import Loader from 'components/Loader'
import Layout, { AgentLayout } from 'components/Layout'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'

const Auth = lazy(() => import('./pages/Auth'))
const OAUTH = lazy(() => import('./pages/OAUTH'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const Promotion = lazy(() => import('./pages/Promotion'))
const Claim = lazy(() => import('./pages/Claim'))
const Confirmation = lazy(() => import('./pages/Confirmation'))
const Verify = lazy(() => import('./pages/Verify'))
const Authorize = lazy(() => import('./pages/Authorize'))
const FAQs = lazy(() => import('./pages/FAQ'))

const ASPClaim = lazy(() => import('./pages/Claim/ASP'))

const AgentDashboard = lazy(() => import('./pages/Internal/Dashboard'))
const Claims = lazy(() => import('./pages/Internal/Claims'))
const AgentClaim = lazy(() => import('./pages/Internal/Claim'))
const AgentClaimReview = lazy(() => import('./pages/Internal/Claim/Review'))
const Exports = lazy(() => import('./pages/Internal/Exports'))
const Import = lazy(() => import('./pages/Internal/Import'))
const Reports = lazy(() => import('./pages/Internal/Reports'))

function LayoutController({ children }) {
	const { user } = useAuthentication()

	if (user.userType === 'AGENT') return <AgentLayout>{children}</AgentLayout>

	return (
		<Layout>
			<Header />
			{children}
		</Layout>
	)
}

export default function Routes() {
	const { isAuthenticated, user } = useAuthentication()

	if (isAuthenticated)
		return (
			<Router>
				<LayoutController>
					<Suspense fallback={<Loader />}>
						<Switch>
							{user.userType === 'AGENT' && (
								<>
									<Route
										path="/"
										exact
										component={AgentDashboard}
									/>
									<Route path="/claims" component={Claims} />
									<Route
										path="/claim/:claimId"
										component={AgentClaim}
									/>
									<Route
										path="/claim-review/:claimId"
										component={AgentClaimReview}
									/>
									<Route
										path="/exports"
										component={Exports}
									/>
									<Route path="/import" component={Import} />
									<Route
										path="/reports"
										component={Reports}
									/>
								</>
							)}
							<Route path="/" exact component={Dashboard} />
							<Route
								path="/promotion/:promoCode"
								component={Promotion}
							/>
							<Route
								path="/confirmation/:claimId"
								component={Confirmation}
							/>
							<Route
								path="/claim/:promoCode"
								component={
									user.userType === 'CONSUMER'
										? Claim
										: ASPClaim
								}
							/>
							<Route
								path="/account/:language/:status"
								component={Verify}
							/>
							<Route
								path="/frequently-asked-questions"
								component={FAQs}
							/>
							<Route
								path="/oauth/:language/:token"
								component={OAUTH}
							/>
							<Route component={Redirect} />
						</Switch>
					</Suspense>
				</LayoutController>
			</Router>
		)

	return (
		<Router>
			<Layout>
				<Header />
				<Suspense fallback={<Loader />}>
					<Switch>
						<Route path="/" exact component={Auth} />
						<Route
							path="/account/:language/:status"
							component={Verify}
						/>
						<Route
							path="/auth/:language/unauthorized"
							component={Authorize}
						/>
						<Route
							path="/auth/:language/:accessToken/:refreshToken"
							component={Authorize}
						/>
						<Route
							path="/frequently-asked-questions"
							component={FAQs}
						/>
						<Route
							path="/p/:language/:promoCode?"
							component={PromoCode}
						/>
						<Route
							path="/oauth/:language/:token"
							component={OAUTH}
						/>
						<Route component={Redirect} />
					</Switch>
				</Suspense>
			</Layout>
		</Router>
	)
}

function Redirect() {
	const history = useHistory()
	useEffect(() => {
		history.push('/')
	}, [history])
	return null
}

function PromoCode() {
	const { i18n } = useTranslation()
	const { language, promoCode } = useParams()

	useEffect(() => {
		if (i18n.language !== language) i18n.changeLanguage(language)
		// eslint-disable-next-line
	}, [language])

	return (
		<div>
			<p>
				<strong>Language:</strong> {language}
			</p>
			<p>
				<strong>Promo code:</strong> {promoCode || 'NO PROMO CODE'}
			</p>
		</div>
	)
}
