import React, { createContext, useContext, useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { useApolloClient } from '@apollo/client'
import { REFRESH_TOKEN_CONSUMER, REFRESH_TOKEN } from 'operations/Auth'
// import { useGlobalState } from '../hooks/useGlobalState'
// import * as Sentry from '@sentry/browser'

const AuthenticationContext = createContext()

function AuthenticationProvider({ init = false, children }) {
	const client = useApolloClient()

	const [isAuthenticated, setAuthenticated] = useState(init)
	const [user, setUser] = useState({})
	const [tokenExpires, setTokenExpiry] = useState()
	// const { resetGlobalState } = useGlobalState()

	// if (user) {
	//     Sentry.configureScope(scope => {
	//         scope.setUser({
	//             id: user.sub,
	//             username: user.navisionId,
	//             userType: user.userType
	//         })
	//     })
	// }

	useEffect(() => {
		const accessToken = localStorage.getItem('accessToken') || false
		if (accessToken) {
			const decoded = jwtDecode(accessToken)
			const { knd, exp } = decoded
			if (knd === 'access' && exp > moment().unix()) {
				setTokenExpiry(exp)
				setUser(decoded)
				setAuthenticated(true)
			} else {
				console.log('authentication line 40', exp, moment().unix())

				localStorage.removeItem('accessToken')
				localStorage.removeItem('refreshToken')
				setAuthenticated(false)
				// resetGlobalState()
				client.clearStore()
			}
		}
		// eslint-disable-next-line
	}, [isAuthenticated])

	useEffect(() => {
		const refreshTimer = setInterval(() => {
			if (tokenExpires < moment().add(10, 'minutes').unix()) {
				const refreshToken =
					localStorage.getItem('refreshToken') || null

				if (refreshToken) {
					client
						.mutate({
							mutation:
								user.userType === 'CONSUMER'
									? REFRESH_TOKEN_CONSUMER
									: REFRESH_TOKEN,
							variables: {
								payload: {
									refreshToken,
								},
							},
						})
						.then(({ data }) => {
							const { accessToken } = data.refreshToken
							const { exp } = jwtDecode(accessToken)
							localStorage.setItem('accessToken', accessToken)
							setTokenExpiry(exp)
						})
						.catch(err => {
							localStorage.removeItem('accessToken')
							localStorage.removeItem('refreshToken')
							setTokenExpiry()
							setAuthenticated(false)
							setUser({})
							// resetGlobalState()
						})
				} else {
					localStorage.removeItem('accessToken')
					localStorage.removeItem('refreshToken')
					setTokenExpiry()
					setAuthenticated(false)
					setUser({})
					// resetGlobalState()
				}
			}
		}, 1000)

		return () => {
			clearInterval(refreshTimer)
		}

		// eslint-disable-next-line
	}, [tokenExpires])

	return (
		<AuthenticationContext.Provider
			value={{
				isAuthenticated,
				user,
				setAuthenticated: (...args) => setAuthenticated(...args),
			}}
		>
			{children}
		</AuthenticationContext.Provider>
	)
}

function useAuthentication() {
	return useContext(AuthenticationContext)
}

export { useAuthentication }

export default AuthenticationProvider
